import {
  AspectRatio,
  Badge,
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "@clerk/clerk-react";
import { format } from "date-fns";
import * as React from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SearchResultsContext } from "../contexts/SearchResultsContext";
import { removeFavorite, setFavorite } from "../helpers/fetch";
import {
  addDays,
  formatDateToYYYYMMDD,
  getChannelDisplayName,
  reformatDateString,
  subtractDays
} from "../helpers/helpers";
import { addQuoteItem, getActiveQuoteVariable } from "../helpers/quoteService";
import { Gallery } from "./Gallery";

type Props = {
  loading?: boolean;
  houseNumber: String;
  rating: number;
  reviews: number;
  occupancy: number;
  bedrooms: number;
  bathrooms: number;
  price?: number;
  channelSubTotalNightly?: number;
  channelSubTotal?: number;
  rebookSubTotalNightly?: number;
  rebookSubTotal?: number;
  rebookDiscount?: number;
  rebookDiscountNightly?: number;
  channel?: String;
  title: String;
  thumbnail?: string;
  pictures: any;
  latitude: number;
  longitude: number;
  address: String;
  town: String;
  amenities?: any;
  favorite?: Boolean;
  setFavorites?: Function;
  favoritesArray?: any;
  listing?: any;
  flexSearch?: any;
};

const SearchResultsCard: React.FC<Props> = ({
  houseNumber,
  rating,
  reviews,
  occupancy,
  bedrooms,
  bathrooms,
  price,
  channelSubTotalNightly,
  rebookDiscount,
  channel,
  title,
  thumbnail,
  pictures,
  town,
  favorite,
  setFavorites,
  favoritesArray,
  listing,
  flexSearch,
}) => {
  const {
    priceSearch,
    contextDateRange,
    guests,
    userEmail,
    setUserEmail,
    numFavorites,
    setNumFavorites,
    searchWithDates,
    activeQuoteNumber,
    setActiveQuoteNumber,
  } = React.useContext(SearchResultsContext);

  const navigate = useNavigate();

  const user = useUser();
  const userIsAdmin =
    user &&
    user.isSignedIn &&
    user.user.primaryEmailAddress &&
    user.user.primaryEmailAddress.emailAddress &&
    (user.user.primaryEmailAddress.emailAddress.includes("darrenhe.com") ||
      user.user.primaryEmailAddress.emailAddress.includes(
        "cloudthoughts.com"
      ) ||
      user.user.primaryEmailAddress.emailAddress.includes("thisisdelmar.com"));

  const [isFavorite, setIsFavorite] = React.useState(favorite);
  const [showModal, setShowModal] = React.useState(false);
  const [emailInput, setEmailInput] = React.useState("");
  const [flexDateString, setFlexDateString] = React.useState(null);
  const [addedToQuote, setAddedToQuote] = React.useState(false);

  const navigationBehavior = useBreakpointValue({
    base: "mobile",
    md: "desktop",
  });

  //console.log('contextDateRange', contextDateRange, 'guests', guests, 'activeQuoteNumber', activeQuoteNumber, 'searchWithDates', searchWithDates);

  React.useEffect(() => {
    //console.log("Flex data: ", houseNumber, flexSearch, contextDateRange)
    if (flexSearch && contextDateRange) {
      const newStartDate = flexSearch.checkIn;
      const newEndDate = flexSearch.checkOut;
      setFlexDateString(
        `${reformatDateString(newStartDate)} - ${reformatDateString(newEndDate)}`
      );
    }
    if (userIsAdmin && !activeQuoteNumber) {
      const localQuoteVariable = getActiveQuoteVariable();
      setActiveQuoteNumber(localQuoteVariable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setIsFavorite(favorite);
  }, [favorite]);

  const handleClick = () => {
    // Initialize dates to contextDateRange values
    let startDate = contextDateRange[0];
    let endDate = contextDateRange[1];

    // Serialize parameters into a query string
    const queryParams = new URLSearchParams();
    if (startDate && endDate) {
      const formattedStartDate = flexSearch ? flexSearch.checkIn : format(startDate, "yyyy-MM-dd");
      const formattedEndDate = flexSearch ? flexSearch.checkOut : format(endDate, "yyyy-MM-dd");
      queryParams.set("startdate", formattedStartDate);
      queryParams.set("enddate", formattedEndDate);
    }
    if (guests) {
      queryParams.set("guests", guests.toString());
    }

    const queryString = queryParams.toString();
    const url = `/${houseNumber}?${queryString}`;
    //console.log("queryString", queryString);

    // Navigate based on navigationBehavior
    if (navigationBehavior === "mobile") {
      navigate(url);
    } else {
      window.open(url, "_blank");
    }
  };

  const handleFavoriteClick = async (e) => {
    e.stopPropagation(); // Prevents the navigate function from firing when the icon is clicked

    if (!userEmail) {
      setShowModal(true);
      return;
    }

    setIsFavorite(!isFavorite);

    try {
      if (!isFavorite) {
        // Add to favorites
        favoritesArray.push(listing);
        setFavorites(favoritesArray);

        await setFavorite(houseNumber, userEmail);
        setNumFavorites(numFavorites + 1);
      } else {
        // Remove from favorites
        let index = favoritesArray.findIndex(
          (favorite) => favorite.houseNumber === listing.houseNumber.toString()
        );
        if (index !== -1) {
          favoritesArray.splice(index, 1);
          setFavorites(favoritesArray);
        }

        await removeFavorite(houseNumber, userEmail);
        setNumFavorites(numFavorites - 1);
      }
    } catch (error) {
      console.error("Error handling favorite:", error);
      // Handle error (show a message to the user, rollback state changes, etc.)
    }
  };

  const addToQuote = async (e) => {
    e.stopPropagation(); // Prevents the navigate function from firing when the icon is clicked

    try {
      if (
        activeQuoteNumber &&
        contextDateRange &&
        contextDateRange[0] &&
        contextDateRange[1] &&
        guests
      ) {
        // Initialize dates to contextDateRange values
        let startDate = contextDateRange[0];
        let endDate = contextDateRange[1];

        // Adjust dates based on flexSearch without changing contextDateRange
        if (flexSearch && contextDateRange) {
          if (flexSearch === "PLUS_ONE_WEEK") {
            startDate = addDays(contextDateRange[0], 7);
            endDate = addDays(contextDateRange[1], 7);
          } else if (flexSearch === "MINUS_ONE_WEEK") {
            startDate = subtractDays(contextDateRange[0], 7);
            endDate = subtractDays(contextDateRange[1], 7);
          }
        }

        const quoteItemData = {
          houseNumber: houseNumber,
          checkinDate: formatDateToYYYYMMDD(startDate),
          checkoutDate: formatDateToYYYYMMDD(endDate),
          guests: guests,
        };
        await addQuoteItem(activeQuoteNumber, quoteItemData);
        setAddedToQuote(true);
      }
    } catch (error) {
      console.error("Error adding to quote:", error);
    }
  };

  const handleModalSubmit = async () => {
    localStorage.setItem("userEmail", emailInput);
    setUserEmail(emailInput);
    setShowModal(false);

    if (!isFavorite) {
      try {
        await setFavorite(houseNumber, emailInput);
        setIsFavorite(true);
        //console.log("Added to favorites:", houseNumber);
      } catch (error) {
        //console.error("Error setting favorite:", error);
        // Handle error (placeholder)
      }
    } else {
      try {
        await removeFavorite(houseNumber, emailInput);
        setIsFavorite(false);
        //console.log("Removed from favorites:", houseNumber);
      } catch (error) {
        //console.error("Error removing favorite:", error);
        // Handle error (placeholder)
      }
    }
  };

  // Prepare images for the Gallery, limited to the first 10 images, with first image coming as thumbnail
  const galleryImages = [
    {
      src:
        thumbnail ||
        "media/comingsoon.jpg",
      alt: `Thumbnail of Del Mar #${houseNumber}`,
    },
    ...pictures.slice(1, 10).map((pic, index) => ({
      src: pic.original,
      alt: `Photo of Del Mar #${houseNumber + index + 1}`,
    })),
  ];

  //console.log("SearchResultsCard received channel:", channel);
  //console.log("Check channel conditions:", channel, channelSubTotalNightly, price);
  //console.log("Image check:", thumbnail, pictures); 

  return (
    <Flex
      className="listing-card"
      flexDirection={"column"}
      m={2}
      onClick={handleClick}
      _hover={{ cursor: "pointer" }}
      position="relative"
    >
      {thumbnail || pictures[0]?.original ? (
        <Gallery images={galleryImages} aspectRatio={1.2} />
      ) : (
        <AspectRatio width="100%" ratio={1.2}>
          <Image
            src={
              "media/comingsoon.jpg"
            }
            alt={"Photo of Del Mar #" + houseNumber}
            objectFit="cover"
            width="100%"
            height="100%"
            className="listing-rating-star"
          />
        </AspectRatio>
      )}

      <Box
        position="absolute"
        top="12px"
        right="12px"
        onClick={handleFavoriteClick}
      >
        {isFavorite ? (
          <FaHeart color={"#e95037"} size="1.25em" />
        ) : (
          <FaRegHeart color={"white"} size="1.25em" />
        )}
      </Box>

      {activeQuoteNumber &&
        contextDateRange &&
        contextDateRange[0] &&
        contextDateRange[1] &&
        guests &&
        searchWithDates && (
          <Button
            position="absolute"
            top="12px"
            left="12px"
            onClick={addToQuote}
            isDisabled={addedToQuote}
          >
            {addedToQuote ? "Added to Quote" : "Add to Quote"}
          </Button>
        )}

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Your Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Email address"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="dmOrange" mb={2} onClick={handleModalSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        className="listing-summary"
        justifyContent={"space-between"}
        mt={2}
        px={1}
        color={flexSearch ? "white" : "unset"}
      >
        <Flex className="listing-number" fontWeight={500} fontSize={"16px"}>
          #{houseNumber}
        </Flex>
        <Flex className="listing-reviews-parent" alignItems={"center"} px={1}>
          {reviews !== null && !isNaN(reviews) && reviews > 0 && (
            <>
              <Flex className="listing-rating" fontSize={"16px"}>
                {rating !== null && !isNaN(rating)
                  ? Number(rating.toFixed(1))
                  : 0}
              </Flex>
              <Image
                src={"../media/ratingstar.png"}
                alt="Home Rating"
                objectFit={"cover"}
                align={"left"}
                width={"18px"}
                height={"18px"}
                className="listing-rating-star"
                mx={1.5}
              />
            </>
          )}
          <Flex className="listing-reviews" fontSize={"16px"}>
            {reviews !== null &&
              !isNaN(reviews) &&
              (reviews === 0 ? "New Listing" : `${reviews as number} Reviews`)}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        className="listing-description"
        fontSize={"15px"}
        textAlign={"left"}
        mt={3}
        px={1}
        fontWeight={500}
        color={flexSearch ? "white" : "dmNavy.500"}
      >
        {title}
      </Flex>

      <Flex
        className="listing-details"
        fontSize={"14px"}
        gap={2}
        alignItems={"flex-start"}
        color={flexSearch ? "white" : "dmNavy.400"}
        mt={1}
        px={1}
      >
        <Text className="listing-occupancy-text">
          {!isNaN(occupancy) ? (occupancy as number) : 0} Guests
        </Text>
        {bedrooms && (
          <>
            <Text color={"dmOrange.500"} fontWeight={400}>
              \
            </Text>
            <Text className="listing-bedrooms-text">
              {!isNaN(bedrooms) ? (bedrooms as number) : 0} Beds
            </Text>
          </>
        )}
        {bathrooms && (
          <>
            <Text color={"dmOrange.500"} fontWeight={400}>
              \
            </Text>
            <Text className="listing-bathrooms-text">
              {!isNaN(bathrooms) ? (bathrooms as number) : 0} Baths
            </Text>
          </>
        )}
      </Flex>

      <Flex
        className="listing-price"
        fontSize={"15px"}
        mt={2}
        px={1}
        flexDirection="column"
        textAlign={"left"}
      >
        <Text fontWeight={400}
          color={flexSearch ? "white" : "dmNavy.500"}
        >{town ? town + ", MA" : ""}
        </Text>

        {channel && getChannelDisplayName(channel.toString()) && channelSubTotalNightly > 0 && (channelSubTotalNightly > price) && (
          <Box as="span" style={{ textDecoration: 'line-through', color: '#e9503790' }}>
            <Box fontWeight={400} fontSize={'13px'} color={"dmOrange.500"}>
              <i>{getChannelDisplayName(channel.toString())} Rate: ${channelSubTotalNightly.toFixed(0)} per night</i>
            </Box>
          </Box>
        )}

        <Box fontWeight={400} color={flexSearch ? "white" : "dmNavy.500"}>
          {!isNaN(price) && price !== null ? (
            <>
              {/* Book Direct Prefix and Savings Badge when applicable */}
              {channel && getChannelDisplayName(channel.toString()) && channelSubTotalNightly && channelSubTotalNightly > price ? (
                <Flex gap={1} alignItems={'center'} flexWrap={'wrap'}>
                  <Box>
                    <b>Our Rate:</b> {priceSearch && searchWithDates ? `From $${price.toFixed(0)} per night` : `$${price.toFixed(0)} per night`}
                  </Box>
                  <Badge colorScheme="green">
                    Save ${Math.round(rebookDiscount)} on this stay
                  </Badge>
                </Flex>
              ) : (
                priceSearch && searchWithDates ? `From $${price.toFixed(0)} per night` : `$${price.toFixed(0)} avg per night`
              )}
            </>
          ) : ""}
        </Box>
      </Flex>

      {flexDateString && (
        <Text
          textAlign={"left"}
          fontWeight={"700"}
          fontSize={"15px"}
          color={"dmOrange.500"}
          mt={1}
          px={1}
        >
          Available: {flexDateString}
        </Text>
      )}
    </Flex>
  );
};

export { SearchResultsCard };

export const SearchResultsCardSkeleton: React.FC = () => {
  return (
    <Flex className="listing-card" flexDirection={"column"} m={2}>
      <AspectRatio width="100%" ratio={1.2}>
        <Skeleton height="100%" width="100%" borderRadius={10} />
      </AspectRatio>

      <Flex justifyContent={"space-between"} mt={2} px={1}>
        <Skeleton height="20px" width="50px" />
        <Skeleton height="20px" width="100px" />
      </Flex>

      <Flex fontSize={"12px"} alignItems={"flex-start"} mt={2} px={1}>
        <Skeleton height="50px" width="50px" mr={3} />
        <Skeleton height="50px" width="50px" mr={3} />
        <Skeleton height="50px" width="50px" />
      </Flex>

      <Skeleton height="16px" mt={2} width="200px" />
      <Skeleton height="16px" mt={2} width="100px" />
    </Flex>
  );
};
