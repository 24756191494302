import { BigNumber } from "bignumber.js";
import { addMinutes, max, subDays } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { isNull, isUndefined, omitBy } from "lodash-es";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../config";

export const omitNullAndUndefined = (obj) => {
  return omitBy(obj, (value) => isUndefined(value) || isNull(value));
};

export const isEmptyObj = (obj: {}) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isEmptyArr = (arr: any) => Array.isArray(arr) && arr.length === 0;
export const isNil = (v: any) =>
  !v ||
  v === undefined ||
  v === null ||
  isEmptyObj(v) ||
  isEmptyArr(v) ||
  v === "";

// Convert DB Date and Time Formats to User Friendly Strings
export const formatDateTimeString = (dateStr, timeStr) => {
  const [year, month, day] = dateStr.split("-").map(Number);
  const [hour, minute] = timeStr.split(":").map(Number);

  // Create a new Date object
  const date = new Date(year, month - 1, day, hour, minute);

  // Format date and time
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${formattedDate} ${formattedTime}`;
};

// Function to format Date as YYYY-MM-DD
export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Formats Date as Nov 13, 2023
export const formatFriendlyDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(date);
};

// Formats Date as Nov 13, 2023
export const formatFriendlyDateShorter = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
  }).format(date);
};

// Currency Formatter
export const formatCurrency = (amount) =>
  amount &&
  amount.toLocaleString("en-US", { style: "currency", currency: "USD" });

// Days between two date strings
export const calculateDaysBetweenDates = (
  checkinDate: string,
  checkoutDate: string
) => {
  const checkin = new Date(checkinDate);
  const checkout = new Date(checkoutDate);
  const timeDifference = checkout.getTime() - checkin.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return Math.floor(daysDifference);
};

// Function to calculate days from today (in Eastern Time) to checkinDate
export const calculateDaysToCheckin = (checkinDate: string) => {
  // Get today's date in Eastern Time
  const todayEasternTime = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  const todayDate = new Date(todayEasternTime);

  //console.log('todayDate',todayDate)

  // Parse checkinDate
  const checkin = new Date(checkinDate);
  //console.log('checkin',checkin)

  // Calculate difference
  const timeDifference = checkin.getTime() - todayDate.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  //console.log('timeDifference',timeDifference,'daysDifference',daysDifference,'floor',Math.floor(daysDifference))

  return Math.floor(daysDifference);
};

export const formatTimestamp = (timestamp: string): string => {
  const timeZone = "America/New_York";
  const zonedDate = utcToZonedTime(timestamp, timeZone);
  return format(zonedDate, "M/d/yy h:mm aa", { timeZone });
};

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const subtractDays = (date: Date, days: number): Date => {
  return addDays(date, -days); // Reusing addDays for subtraction
};

export const formatDate = (date: Date): string => {
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const parseDateToLocalTime = (dateString) => {
  if (!dateString) return null;

  // Split the date string into its components
  const [year, month, day] = dateString.split("-").map(Number);

  // Create a new Date object using local time components
  // Note: Month in JavaScript Date is 0-indexed, so subtract 1
  return new Date(year, month - 1, day);
};

export const formatTime = (timeString: any) => {
  let hour = parseInt(timeString.split(":")[0]);
  let minute = parseInt(timeString.split(":")[1]);
  let minuteString = "";

  if (minute >= 0 && minute <= 9) {
    minuteString = "0" + minute;
  } else {
    minuteString = minute.toString();
  }

  if (hour >= 13) {
    hour = hour - 12;
    return hour + ":" + minuteString + " PM";
  } else if (hour === 12) {
    hour = 12;
    return hour + ":" + minuteString + " PM";
  } else if (hour === 0) {
    hour = 12;
    return hour + ":" + minuteString + " AM";
  } else {
    return hour + ":" + minuteString + " AM";
  }
};

export const reformatDateString = (date) => {
  const [year, month, day] = date.split("-");
  return `${Number(month)}/${Number(day)}/${year}`;
};

export const calcHaversineDistace = (
  sourceLat,
  sourceLong,
  destLat,
  destLong
) => {
  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = sourceLat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = destLat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (sourceLong - destLong) * (Math.PI / 180); // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
        Math.cos(rlat1) *
        Math.cos(rlat2) *
        Math.sin(difflon / 2) *
        Math.sin(difflon / 2)
      )
    );

  return d.toPrecision(3).toString();
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const createDateAtLocalMidnight = (dateString) => {
  if (!dateString) return null;

  // Split the date string into its components
  const [year, month, day] = dateString.split("-").map(Number);

  // Create a new Date object using local time components and set the time to midnight
  // Note: Month in JavaScript Date is 0-indexed, so subtract 1
  return new Date(year, month - 1, day, 0, 0, 0, 0);
};

export const isValidDate = (dateStr) => {
  const date = new Date(dateStr);
  return date instanceof Date && !isNaN(date.getTime()); // Use getTime() to get the timestamp
};

export function decrementGuestInput(
  setGuests: React.Dispatch<React.SetStateAction<number>>,
  prevGuests: number
) {
  setGuests(() => {
    const currentGuests = isNaN(prevGuests) ? 1 : prevGuests;
    return Math.max(1, currentGuests - 1);
  });
}

export function incrementGuestInput(
  setGuests: React.Dispatch<React.SetStateAction<number>>,
  prevGuests: number,
  occupancy: number
) {
  setGuests(() => {
    const currentGuests = isNaN(prevGuests) ? 1 : prevGuests;
    return Math.min(occupancy, currentGuests + 1);
  });
}

type Deposit = { payAt: string; amount: number; note: string };

export const splitPaymentToDeposits = (
  amount: number,
  checkIn: string
): Deposit[] => {
  // Input 299: One payment
  // Input 301: 300 + 0.5 + 0.5
  // Input 301.01: 300 + 0.51 + 0.5

  const nowPlus15Minutes = addMinutes(new Date(), 15);
  const secondPaymentDate = subDays(new Date(checkIn), 180);
  const thirdPaymentDate = subDays(new Date(checkIn), 60);

  /*console.log(
    "nowPlus15Minutes",
    nowPlus15Minutes,
    "secondPaymentDate",
    secondPaymentDate,
    "thirdPaymentDate",
    thirdPaymentDate
  );*/

  const initialDeposit = 300;
  const firstPayment = {
    payAt: nowPlus15Minutes.toISOString(),
    amount: 0,
    note: "Rebook Payment 1: $300 deposit",
  };
  const secondPayment = {
    payAt: max([secondPaymentDate, nowPlus15Minutes]).toISOString(),
    amount: 0,
    note: "Rebook Payment 2: 50% of total amount after deposit",
  };
  const thirdPayment = {
    payAt: max([thirdPaymentDate, nowPlus15Minutes]).toISOString(),
    amount: 0,
    note: "Rebook Payment 3: 50% of total amount after deposit",
  };

  const bigAmount = BigNumber(amount);

  if (bigAmount.lte(initialDeposit)) {
    firstPayment.amount = amount;
    return [firstPayment];
  }

  firstPayment.amount = initialDeposit;
  const smallConsecutive = Number(
    bigAmount.minus(initialDeposit).div(2).toFixed(2, 1)
  );
  const bigConsecutive = bigAmount
    .minus(firstPayment.amount)
    .minus(smallConsecutive)
    .toNumber();

  secondPayment.amount = bigConsecutive;
  thirdPayment.amount = smallConsecutive;

  const sum = BigNumber(firstPayment.amount)
    .plus(secondPayment.amount)
    .plus(thirdPayment.amount);

  /// TEMPORARY PLACEHOLDER - NEEDS FIXING
  if (!(bigAmount.toFixed(2) === sum.toFixed(2))) {
    throw new Error(
      `Sum ${sum} is different from initial amount ${bigAmount.toNumber()}`
    );
  }

  return [firstPayment, secondPayment, thirdPayment];
};

export const getChannelDisplayName = (channel: string): string | null => {
  // Predefined mappings
  const channelMap: { [key: string]: string | null } = {
    airbnb2: "Airbnb",
    airbnb: "Airbnb",
    Airbnb: "Airbnb",
    scabnb: "Airbnb",
    vrbo: "VRBO",
    homeaway: "VRBO",
    hafamolb: "VRBO",
    bookingcom: "Booking.com",
    marriott: "Marriott",
    manual: "Regular",
    beapi: "Regular",
    direct: "Regular",
    website: "Regular",
  };

  // Process the input string
  const processedChannel = channel
    .trim() // Remove leading/trailing spaces
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9]/g, "") // Remove special characters and hyphens
    .replace("-", ""); // Additional check for any hyphens that remain

  // Map the processed string to the display name
  return channelMap[processedChannel] ?? null;
};

export const getHubspotUTK = () => {
  const cookies = document.cookie.split(";");
  const hubspotCookie = cookies.find((cookie) =>
    cookie.trim().startsWith("hubspotutk=")
  );
  return hubspotCookie ? hubspotCookie.split("=")[1] : null;
};

export const convertStringtoDateAndFormat = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export async function encryptJson(dataRaw: any) {
  const data = JSON.stringify(dataRaw);
  const enc = new TextEncoder();
  const keyData = Uint8Array.from(atob(config.encodeGuestyKey), (c) =>
    c.charCodeAt(0)
  );
  const key = await crypto.subtle.importKey("raw", keyData, "AES-GCM", false, [
    "encrypt",
  ]);

  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encrypted = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv },
    key,
    enc.encode(data)
  );

  // Combine IV + ciphertext
  const full = new Uint8Array([...iv, ...new Uint8Array(encrypted)]);
  return btoa(String.fromCharCode(...full));
}


export const isCurrentOrPreviousYear = (dateStr) => {
  const checkinYear = new Date(dateStr).getFullYear();
  const currentYear = new Date().getFullYear();
  return checkinYear === currentYear || checkinYear === currentYear - 1 ? true : false;
};


export async function hashEmail(email: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(email.trim().toLowerCase());
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
}